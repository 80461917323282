<template>
  <v-app>
    <div
      v-bind:class="{
        bgSvg: !$vuetify.theme.dark,
        bgSvgDark: $vuetify.theme.dark
      }"
    >
      <v-flex
        class="backDropper pt-3 pb-5 px-4"
        v-bind:style="{
          'color:rgb(255, 255, 255,0.5)': !$vuetify.theme.dark,
          'color:rgb(0,0,0)': $vuetify.theme.dark
        }"
      >
      <v-dialog v-model="dialog" max-width="50vw" persistent>
      <v-card>
      
      <div class="text-center pa-5">
       <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
    Submitting your complaint Please wait
      </div>
      </v-card>
      
      </v-dialog>
        <v-card class="text-center"
          ><Grievances
            class="ma-3"
            style="min-height:70vh !important; outline:none !important"
            :nulmSelect="userInfo.nulm"
            :sep="grievance"
            :estp="grievance"
            :susv="grievance"
          />
          <v-btn
            color="primary"
            class="buttonGlass ma-2 mb-6"
            @click="submit(grievance)"
          >
            Submit
          </v-btn></v-card
        >
      </v-flex>
    </div>
  </v-app>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import Grievances from '../routes/UserGrievances.vue'
import { mapState } from 'vuex'
export default {
  data: () => ({
    dialog:false,
    grievance: {}
  }),
  components: {
    Grievances
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    submit (e) {
      this.dialog=true
      let userGrievancesExist
      if (this.userInfo.grievance) {
        userGrievancesExist = firebase.firestore.FieldValue.arrayUnion(e)
      } else {
        userGrievancesExist = [e]
      }

      firebase
        .firestore()
        .collection(localStorage.getItem('userDb'))
        .doc(this.userInfo.id)
        .update({
          grievance: userGrievancesExist
        })
        .then(()=>{
            this.createNotification(this.userInfo.district, e,this.userInfo.name,this.userInfo.nulm,this.userInfo)
        
        }
        )
        .catch(err=>{
          window.alert(err)
          this.dialog=false
        })
    },

     createNotification (district, data, userName, userNulm, rawData) {
      let finalData = {
        createdAt: rawData.created,
        senderId: rawData.id,
        senderNumber: rawData.phone,
        senderName: rawData.name,
        senderNulm: rawData.nulm,
        title: `Grievances from Name:${rawData.name} Nulm:${rawData.nulm}`,
        userPath: `${rawData.nulm.toLowerCase().replace('&', 'n')}_${district}`,
        msg: JSON.stringify(data)
      }
      firebase
        .firestore()
        .collection('notifications')
        .add(finalData)
        .then(doc => {
          firebase
            .firestore()
            .collection('notifications')
            .doc(`cmmu_${district.toLowerCase()}`)
            .update({
              noti: firebase.firestore.FieldValue.arrayUnion({
                body: data,
                createdAt: firebase.firestore.Timestamp.now(),
                id: doc.id,
                noti: `Grievances From ${userName} ${userNulm}`
              })
            })
            .catch(err => {
              if (String(err.message).includes('No document to update')) {
                firebase
                  .firestore()
                  .collection('notifications')
                  .doc(`cmmu_${district.toLowerCase()}`)
                  .set({
                    noti: [
                      {
                        body: data,
                        createdAt: firebase.firestore.Timestamp.now(),
                        id: doc.id,
                        noti: `Grievances From ${this.userInfo.name} ${this.userInfo.nulm}`
                      }
                    ]
                  })
              }
            })
            .finally(()=>{
              this.grievance={}
              this.dialog=false
            })
        })
    },
  }
}
</script>
<style scoped>
.bgSvg {
  min-height: 100vh;

  background-color: var(--v-secondary-darken--20);
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='111' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.46'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.bgSvgDark {
  min-height: 100vh;

  background-color: #07070748;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23060606' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.reportCard {
  min-height: 100vh;
}
.backDropper {
  min-height: 100vh;

  backdrop-filter: blur(15px);
}
.buttonGlass {
  box-sizing: border-box;
  overflow: hidden;
}
.buttonGlass::before {
  position: absolute;
  left: 0%;
  width: 20%;
  backdrop-filter: blur(2px);
  transform: skewX(-40deg) translateX(-3rem);
  background-color: white;
  opacity: 0.4 !important;
  z-index: 2;
  transition: 0.4s ease-out;
  border-radius: none !important;
}
.buttonGlass:hover::before {
  left: 100%;
  transform: skewX(-40deg) translateX(1rem);
}
</style>
